import React from 'react'
import { ErrorMessage, Form, Formik, FieldArray } from 'formik'
import PropTypes from 'prop-types'
import CustomModal from 'components/common/modal/CustomModal'
import CustomDatePicker from 'components/common/custom-datepicker/CustomDatepicker'
import Input from 'components/common/input/Input'
import { agendaSchema } from 'constants/Events'

function AgendaModal({ handleCloseModal, initialValues, formik, index }) {
  return (
    <CustomModal
      size='sm'
      show
      onHide={handleCloseModal}
      heading={`${index !== undefined ? 'Edit' : 'Add'} Agenda`}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={agendaSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (index !== undefined) {
            const currentAgendas = [...formik.values.agenda]
            currentAgendas[index] = { ...values }
            formik.setFieldValue('agenda', currentAgendas)
          } else {
            formik.setFieldValue('agenda', [...formik.values.agenda, values])
          }
          setSubmitting(false)
          handleCloseModal()
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <div className='row'>
              <div className='col-md-12'>
                <div className='field-wrapper'>
                  <Input
                    name='title'
                    handleChange={handleChange}
                    placeholder='Title'
                    label='Title*'
                    value={values.title}
                  />
                  <ErrorMessage
                    className='error-text'
                    component='p'
                    name='title'
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <CustomDatePicker
                  name='startDate'
                  label='Start Date'
                  selected={
                    values.startDate ? new Date(values.startDate) : null
                  }
                  onDateChange={(date) => setFieldValue('startDate', date)}
                  dateFormat='MM/dd/yyyy h:mm a'
                  showTimeSelect
                />
                <ErrorMessage
                  className='error-text'
                  component='p'
                  name='startDate'
                />
              </div>

              <div className='col-md-6'>
                <CustomDatePicker
                  name='endDate'
                  label='End Date'
                  selected={values.endDate ? new Date(values.endDate) : null}
                  onDateChange={(date) => setFieldValue('endDate', date)}
                  dateFormat='MM/dd/yyyy h:mm a'
                  showTimeSelect
                />
                <ErrorMessage
                  className='error-text'
                  component='p'
                  name='endDate'
                />
              </div>

              <div className='col-md-12'>
                <div className='field-wrapper'>
                  <Input
                    name='description'
                    handleChange={handleChange}
                    placeholder='Description'
                    label='Description*'
                    value={values.description || ''}
                    type='textarea'
                  />
                  <ErrorMessage
                    className='error-text'
                    component='p'
                    name='description'
                  />
                </div>
              </div>

              {/* Speakers Section */}
              <div className='col-md-12'>
                <FieldArray name='speakers'>
                  {({ remove, push }) => (
                    <div>
                      {/* <label>Speakers*</label> */}
                      {values.speakers?.map((speaker, speakerIndex) => (
                        <div
                          // key={speakerIndex}
                          className='row mb-3'
                        >
                          <div className='d-flex justify-content-between me-3'>
                            <Input
                              name={`speakers[${speakerIndex}].name`}
                              handleChange={handleChange}
                              placeholder='Speaker Name'
                              label='Name'
                              value={speaker.name}
                            />
                            <Input
                              name={`speakers[${speakerIndex}].designation`}
                              handleChange={handleChange}
                              placeholder='Designation'
                              label='Designation'
                              value={speaker.designation}
                            />
                            <button
                              type='button'
                              className='btn btn-danger align-self-xl-end  mb-3 ms-3'
                              onClick={() => remove(speakerIndex)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}
                      <button
                        type='button'
                        className='btn btn-primary mt-2'
                        onClick={() => push({ name: '', designation: '' })}
                      >
                        Add Speaker
                      </button>
                    </div>
                  )}
                </FieldArray>
                <ErrorMessage
                  className='error-text'
                  component='p'
                  name='speakers'
                />
              </div>

              <div className='col-md-12 d-flex justify-content-end gap-4 mt-2'>
                <button
                  type='button'
                  className='secondary-btn record-btn'
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button type='submit' className='primary-btn record-btn'>
                  Save Agenda
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  )
}

const agendaShape = PropTypes.shape({
  title: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  description: PropTypes.string,
  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      designation: PropTypes.string,
    })
  ),
})
AgendaModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    values: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    errors: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    setFieldError: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  initialValues: agendaShape.isRequired,
}

export default AgendaModal
